
import BookingMenu from "@/components/BookingMenu";
export default {
  components: {
    BookingMenu,
  },
  props: ["title", "subTitle", "img"],
  data() {
    return {
      lang: this.$router.currentRoute.params.lang,
    };
  },
  watch: {
    $route() {
      this.lang = this.$router.currentRoute.params.lang;
    },
  },
};
