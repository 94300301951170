
export default {
  props: ["tranform"],
  components: {},
  data() {
    return {
      award: [
        {
          name: "Sha",
          img: "sha.webp",
        },
        {
          name: "Booking",
          img: "booking.jpg",
        },
        {
          name: "Agoda",
          img: "agoda.png",
        },
      ],
    };
  },
  computed: {
    IsMobile() {
      return this.$q.screen.width < 1023;
    },
  },
};
